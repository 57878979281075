<template>
  <div class="data-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="活動名稱" prop="name" align="center" />
      <el-table-column label="狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'status.tagType')">{{ get(scope.row, 'status.label') }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="開始時間" prop="startAt" width="120px" align="center" />
      <el-table-column label="結束時間" prop="endAt" width="120px" align="center" />
      <el-table-column label="總登錄次數" prop="usedCode" align="center" />
      <el-table-column label="獎勵紀錄" prop="name" align="center">
        <template slot-scope="scope">
          <router-link :to="{name: 'SerialActivityRewardRecord', params: {id: scope.row.id} }">
            <el-button class="underline text-primary-100" type="text">檢視</el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="序號管理" prop="name" align="center">
        <template slot-scope="scope">
          <router-link :to="{name: 'SerialCodeManagement', params: {id: scope.row.id} }">
            <el-button class="underline text-primary-100" type="text">管理</el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="活動連結" prop="name" align="center">
        <template slot-scope="scope">
          <el-button class="underline text-primary-100" type="text" @click="onCopyActivityLink(scope.row)">複製</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteRow"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import Tag from '@/components/Tag/Tag.vue'
import { DeleteSerialActivity as DeleteDataAPI } from '@/api/serialActivity'
import { useTable } from '@/use/table'
import { serialActivityStatusConfig } from '@/config/serialActivity'
import { get, map } from 'lodash'
import { useRouter } from 'vue-router/composables'
import copy from 'clipboard-copy'

export default defineComponent({
  name: 'RewardActivityTable',
  components: { EmptyBlock, Tag },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const { shopId, dateFormat } = useTable()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      const data = props.tableData
      // handle data here
      return map(data, (i) => {
        return {
          id: i.id,
          name: i.name,
          status: get(serialActivityStatusConfig, i.status),
          startAt: dateFormat(i.startAt),
          endAt: dateFormat(i.endAt),
          usedCode: i.usedCode,
          shortLink: i.shortLink,
        }
      })
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'RewardActivityEdit',
        params: { id: row.id },
      })
    }

    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const deleteRow = async () => {
      const [, err] = await DeleteDataAPI({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }

    const onCopyActivityLink = async (row) => {
      await copy(row.shortLink)
      window.$message.success('複製成功！')
    }

    return {
      get,
      modal,
      displayData,
      onRowEdit,
      onRowDelete,
      deleteRow,
      onCopyActivityLink,
    }
  },
})
</script>
