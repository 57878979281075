export const serialActivityStatusConfig = {
  open: { label: '進行中', value: 'open', tagType: 'action' },
  wait: { label: '未上架', value: 'wait', tagType: 'info' },
  finish: { label: '已結束', value: 'finish', tagType: 'danger' },
}
export const serialActivityLogStatusConfig = {
  REDEEM: { label: '登錄成功', value: 'REDEEM', tagType: 'action' },
  FAIL: { label: '登錄失敗', value: 'FAIL', tagType: 'danger' },
}

export const serialRewardTypeConfig = {
  POINT: {
    label: '點數',
    value: 'POINT',
  },
  CASHBACK: {
    label: '回饋金',
    value: 'CASHBACK',
  },
}

export const serialCodeImportExample = [
  { 序號: '123456789' },
  { 序號: 'HD94JFOV' },
  { 序號: 'HD946FOB' },
  { 序號: 'HD926HOA' },
]

export const batchImportErrorCodeConfig = {
  UNIQUE_CONSTRAINT_ERROR: { label: '序號重複', value: 'UNIQUE_CONSTRAINT_ERROR' },
  EMPTY_CODE: { label: '序號空白', value: 'EMPTY_CODE' },
  INVALID_CODE: { label: '無效序號', value: 'INVALID_CODE' },
}

export const serialActivityCodeOriginConfig = {
  MANUAL_SINGLE: { label: '單次新增', value: 'MANUAL_SINGLE' },
  MANUAL_BATCH: { label: '大量匯入', value: 'MANUAL_BATCH' },
  SYSTEM: { label: '系統生成', value: 'SYSTEM' },
}
